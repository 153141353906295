import React from "react";
import { Link } from "gatsby";

export type PageContext = {
  previousPagePath: string;
  nextPagePath: string;
  humanPageNumber: string;
  numberOfPages: number;
};

type Props = {
  pageContext: PageContext;
};

const Pagination = ({ pageContext }: Props) => {
  const {
    previousPagePath,
    nextPagePath,
    humanPageNumber,
    numberOfPages
  } = pageContext;

  return (
    <nav className="pagination" role="navigation">
      <div>
        {previousPagePath && (
          <Link to={previousPagePath} rel="prev">
            Vorherige
          </Link>
        )}
      </div>
      {numberOfPages > 1 && (
        <div className="pagination-location">
          Seite {humanPageNumber} von {numberOfPages}
        </div>
      )}
      <div>
        {nextPagePath && (
          <Link to={nextPagePath} rel="next">
            Nächste
          </Link>
        )}
      </div>
    </nav>
  );
};

export default Pagination;
