import React from "react";
import { graphql } from "gatsby";

import { PageContext } from "~/components/Pagination";

import { Layout, PostCard, Pagination } from "../components/";
import { MetaData } from "../components/meta";

type Props = {
  data: {
    ghostTag: {
      name: string;
      description: string;
    };
    allGhostPost: Record<string, unknown>;
  };
  location: Location;
  pageContext: PageContext;
};

/**
 * Tag page (/tag/:slug)
 *
 * Loads all posts for the requested tag incl. pagination.
 */
const Tag = ({ data, location, pageContext }: Props) => {
  const tag = data.ghostTag;
  const posts = data.allGhostPost.edges;

  return (
    <>
      <MetaData data={data} location={location} type="series" />
      <Layout location={location}>
        <header className="tag-header">
          <h1>{tag.name}</h1>
          {tag.description ? <p>{tag.description}</p> : null}
        </header>
        <section className="post-feed">
          {(posts as any).map(({ node }) => (
            <PostCard key={node.id} post={node} />
          ))}
        </section>
        <Pagination pageContext={pageContext} />
      </Layout>
    </>
  );
};

export default Tag;

export const pageQuery = graphql`
  query GhostTagQuery($slug: String!, $limit: Int!, $skip: Int!) {
    ghostTag(slug: { eq: $slug }) {
      ...GhostTagFields
    }
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`;
