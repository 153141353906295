import React from "react";
import { Link } from "gatsby";
import { Tags } from "@tryghost/helpers-gatsby";
import { readingTime as readingTimeHelper } from "@tryghost/helpers";

type Props = {
  post: {
    slug: string;
    title: string;
    feature_image: string;
    featured: boolean;
    tags: { name: string }[];
    excerpt: string;
    primary_author: {
      name: string;
      profile_image: string;
    };
  };
};

const PostCard = ({ post }: Props) => {
  const url = `/${post.slug}/`;
  const readingTime = readingTimeHelper(post);

  return (
    <Link to={url} className="post-card">
      <header className="post-card-header">
        {post.feature_image && (
          <div
            className="mb-2 bg-cover bg-no-repeat bg-center w-auto"
            style={{
              height: 200,
              backgroundImage: `url(${post.feature_image})`
            }}
          ></div>
        )}
        {post.tags && (
          <div className="text-sm text-accent font-bold tracking-wide">
            <Tags post={post} visibility="public" autolink={false} />
          </div>
        )}
        <h2 className="text-2xl font-bold my-1">{post.title}</h2>
      </header>
      <section className="text-lg leading-relaxed text-gray-700 font-text">
        {post.excerpt}
      </section>
      <footer className="flex justify-between items-center mt-2 text-brand-secondary">
        <span>{post.primary_author.name}</span>
        <div>{readingTime}</div>
      </footer>
    </Link>
  );
};

export default PostCard;
